export const academyLoginTheme = {
  formContainer: {
    'margin': '0'
  },
  formSection: {
    'background': 'none'
  },
  sectionHeader: {
    'fontSize': '35px',
    'color': '#fff',
    'fontWeight': 'bold',
  },
  toast: {
    'background': '#ffc100',
    'color': '#1c1c1c',
  },
  inputLabel: {
    'color': '#fff'
  },
  input: {
    'width': '100%',
    'height': '42px',
    'border': '1px solid #7d7d7d',
    'marginBottom': '30px',
    'padding': '0 15px',
    'outline': 'none',
    'borderRadius': '25px',
    'fontSize': '15px',
    'background': 'none',
    'color': '#fff'
  },
  button: {
    'background': 'var(--amazonOrange)',
    'border': '1px solid var(--amazonOrange)',
    'borderRadius': '25px',
    'fontSize': '15px',
    'outline': 'none',
    'color': '#1c1c1c',
    'fontWeight': 'bold',
    'cursor': 'pointer'
  }
};

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:30095920-6811-4bab-b01e-da7b6d61cca5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Qu7eqqGSN",
    "aws_user_pools_web_client_id": "1tp05bpu80gosahubr3jstg66v",
    "oauth": {}
};


export default awsmobile;

import React from 'react';
import config from '../../aws-exports';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact
} from 'aws-amplify-react';
import { academyLoginTheme, adminLoginTheme } from '../../theme';
import { settings } from '../../configs/settings';
import { signUpConfig, usernameAttributes } from '../../configs/signUpConfig';
import { SignUp as CustomSignUp } from '../custom-pages/SignUp';
import { getRedirectUrl, hasAdmin,createCookie, clearCookie  } from '../../util/util';
import logo from '../../logo.jpg'
import {LogoStyle, CadastarStyle, EsqueceuStyle, BoxLogin} from './styles';
import { Auth } from 'aws-amplify';
import Header from '../header'

export default class Authentication extends React.Component {
  constructor() {
    super();
  }

  redirectToState = () => {
    switch (this.props.page) {
      case 'renew' :
        return 'forgotPassword';
      case 'signUp' :
        return 'signUp';
      default:
        return 'signIn'
    }
  };

  async isAuth() {
    try {
      const response = await Auth.currentSession();
      createCookie(response);

      this.setState({
        authState: 'signedIn'
      })
    }
    catch (e) {
      clearCookie();
      this.setState({
        authState: 'signOut'
      })
    }
  }

  onStateChange = async (authState) => {
    this.setState({ authState });
    await this.isAuth();

    if (authState === 'signedIn') {
      getRedirectUrl()
    }
  };

  render() {
    const { authState, page } = this.props;
    return (
      <div className="base-plan">
        <div className='container'>

        <BoxLogin className='col-md-8 my-5 col-lg-4'>
          {authState !== 'signedIn' ? (
            <div className="row">
               
            </div>
          ) : (
            <div className="force-align-center">
             <div className="spinner"/>
            </div>
          )}
          <Authenticator
            authState={this.redirectToState()}
            amplifyConfig={config}
            onStateChange={this.onStateChange}
            usernameAttributes={usernameAttributes}
            theme={hasAdmin() ? adminLoginTheme : academyLoginTheme}
            hide={[
              SignIn,
              ConfirmSignIn,
              RequireNewPassword,
              SignUp,
              ConfirmSignUp,
              VerifyContact,
              ForgotPassword,
            ]}
            hideDefault={true}
          >
              <div className="text-center logo">
                <a href={process.env.REACT_APP_HOME_PAGE}>
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className="img-fluid logo"
                  /> <h2>Fazer Login</h2>
                </a>
              </div>
            <SignIn/>
            <ConfirmSignIn/>
            <RequireNewPassword/>
            <CustomSignUp override="SignUp" signUpConfig={signUpConfig}/>
            <ConfirmSignUp/>
            <VerifyContact/>
			      <ForgotPassword/>
          </Authenticator>
          </BoxLogin>
        </div>

      </div>
    )
  }
}

import React from 'react';
import logo from '../../logo.jpg'
import { settings } from '../../configs/settings';

export default class Header extends React.Component {

  render() {
    return (
      <div className="col col-12 border-top">
        <div className="text-center logo">
          <a href={process.env.REACT_APP_HOME_PAGE}>
            <img
              src={logo}
              alt={settings.title_site}
              className="img-fluid logo"
            /> <h2>Fazer Login</h2>
          </a>
        </div>
      </div>
    )
  }
}
import React, { Component } from "react";
import "./App.css";
import Amplify, { Auth, I18n } from "aws-amplify";
import awsconfig from "./aws-exports";
import { dict } from "./vocabularies/disct";
import Authentication from "./components/pages/Authentication";
import {
  clearCookie,
  createCookie,
  createSamlCookie,
  redirectTo,
  redirectToLogin,
  redirectToSamlLogin,
} from "./util/util";
import Cookie from "js-cookie";
Amplify.configure(awsconfig);
I18n.setLanguage("pt_BR");
I18n.putVocabularies(dict);

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      authState: "",
      idToken: null,
    };
  }

  async isAuth() {
    try {
      if (localStorage.getItem("samlLoggedIn") === "true") {
        this.setState({
          authState: "signedIn",
        });
        return;
      }

      const response = await Auth.currentSession();
      console.log(response);
      createCookie(response);

      this.setState({
        authState: "signedIn",
      });
    } catch (e) {
      clearCookie();
      this.setState({
        authState: "signOut",
      });
    }
  }

  async componentDidMount() {
    await this.isAuth();
  }

  render() {
    const { authState } = this.state;
    if (authState) {
      return <RenderPage authState={authState} />;
    } else {
      return <div />;
    }
  }
}

const RenderPage = ({ authState }) => {
  const params = window.location.search.split(/[?=]+/);
  const params2 = params[2] ? params[2].split(/[&=]+/) : "";
  let redirect = params2[0] ? atob(params2[0]) : "";

  if (redirect.length <= 0) {
    redirect = Cookie.get("redirect");
  }
  Cookie.set("redirect", redirect);
  const logout = () => {
    Auth.signOut()
      .then(() => {
        clearCookie();
      })
      .catch(() => {
        clearCookie();
      });
    redirectToLogin(redirect);
  };

  const logoutRedirect = () => {
    Auth.signOut()
      .then(() => {
        clearCookie();
      })
      .catch(() => {
        clearCookie();
      });
    redirectTo(redirect);
  };

  switch (params[1]) {
    case "renew":
      return <Authentication page={"renew"} />;
    case "logout":
      logout();
      return <div />;
    case "logoutRedirect":
      logoutRedirect();
      return <div />;
    case "signUp":
      return <Authentication page={"signUp"} />;
    case "login":
      localStorage.setItem("loginRedirect", redirect);
      redirectToSamlLogin();
      return <div />;
    case "loggedIn":
      createSamlCookie();
      const loginRedirect = Cookie.get("redirect");
      localStorage.setItem("samlLoggedIn", "true");
      localStorage.removeItem(loginRedirect);
      redirectTo(loginRedirect);
      return <div />;
    default:
      if (authState === "signOut") {
        return <Authentication />;
      } else if (authState === "signedIn") {
        redirectTo(redirect);
      }
      return <div />;
  }
};
